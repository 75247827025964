.contatoPage {
    position: relative;
    z-index: 2;
    .poligono {
        position: relative;
        display: table;
        width: 100%;
        height: 510px;
        z-index: 2;
        background-image: url($img+"contato/poligonoPage.png");
        background-repeat: no-repeat;
        background-position: center top;
    }
    article {
        position: relative;
        &.chamada {
            background-color: $roxo;
            padding-top: 50px;
            z-index: 2;
            .foto {
                margin-bottom: 50px;
            }
            .texto {
                color: #ffffff;
                text-align: center;
            }
            .psicologos {
                margin-top: -150px;
                .psicologo {
                    margin-bottom: 60px;
                    .bordaFoto {
                        display: table;
                        width: auto;
                        max-width: 250px;
                        margin: auto;
                        overflow: hidden;
                        border-radius: 50%;
                        border: $marrom 4px solid;
                        padding: 0px;
                        .bordaFoto2 {
                            display: block;
                            margin: auto;
                            overflow: hidden;
                            border-radius: 50%;
                            border: $verde 4px solid;
                            .foto {
                                display: block;
                                width: 250px;
                                height: 250px;
                                margin: auto;
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                    h3 {
                        font-size: 24px;
                        line-height: 32px;
                        color: #FFF;
                        text-align: center;
                        margin-bottom: 0px;
                    }
                    .cargo {
                        font-size: 24px;
                        line-height: 32px;
                        color: #FFF;
                        text-align: center;
                    }
                    .botoes {
                        margin-top: 20px;
                        a {
                            display: block;
                            background-color: $verde;
                            padding: 10px;
                            text-align: center;
                            color: #FFF;
                            font-size: 24px;
                            line-height: 32px;
                            margin-bottom: 5px;
                            &:hover {
                                background-color: $marrom;
                                text-decoration: none;
                            }
                            &:active {
                                background-color: $marrom;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            .separador {
                display: table;
                width: 100%;
                height: 4px;
                background-image: url($img+'quemSomos/separador.png');
                background-repeat: no-repeat;
                background-position: center center;
                padding: 50px 0;
            }
        }
    }
    .contato {
        article {
            background-color: $roxo;
        }
    }
}
