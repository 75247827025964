footer {
    background-color: $verde;
    padding: 10px 0;
    .copyright {
        color: $cinza;
        font-size: 21px;
        line-height: 24px;
        padding: 6px 0 0;
        @include breakpoint(768px, max) {
            text-align: center;
            margin-bottom: 10px;
        }
    }
    .closer {
        text-align: right;
        @include breakpoint(768px, max) {
            text-align: center;
        }
        a {
            display: inline-block;
        }
    }
}