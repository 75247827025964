.conectare {
    position: relative;
    z-index: 2;
    .poligono {
        display: table;
        width: 100%;
        height: 160px;
        background-image: url($img+"conectare/poligono.png");
        background-repeat: no-repeat;
        background-position: center top;
    }
    article {
        background-color: $roxo;
        padding-top: 50px;
        .texto {
            color: #ffffff;
            text-align: center;
        }
    }
}
