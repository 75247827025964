.quemSomos {
    position: relative;
    z-index: 2;
    .poligono {
        position: relative;
        display: table;
        width: 100%;
        height: 160px;
        z-index: 2;
        background-image: url($img+"conectare/poligono.png");
        background-repeat: no-repeat;
        background-position: center top;
    }
    article {
        position: relative;
        &.chamada {
            background-color: $roxo;
            padding-top: 50px;
            z-index: 2;
            .foto {
                margin-bottom: 50px;
            }
            .texto {
                color: #ffffff;
                text-align: center;
            }
        }
        &.conteudo {
            background-color: rgba(#fff, 0.8);
            margin: -160px 0;
            padding: 200px 0;
            z-index: 1;
            h3 {
                color: $cinza;
                font-size: 35px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 50px;
            }
            .texto {
                font-size: 18px;
            }
            .texto2 {
                font-size: 18px;
                color: $roxo;
                text-align: center;
            }
            .coluna {
                position: relative;
                padding: 0 40px;
                @include breakpoint(768px, max) {
                    padding: 0 15px;
                }
                &:before {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 220px;
                    left: 0px;
                    top: 50px;
                    background-color: $cinza;
                    @include breakpoint(768px, max) {
                        display: none;
                    }
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
            .psicologos {
                .psicologo {
                    margin-bottom: 60px;
                    .bordaFoto {
                        display: table;
                        width: auto;
                        max-width: 250px;
                        margin: auto;
                        overflow: hidden;
                        border-radius: 50%;
                        border: $marrom 4px solid;
                        padding: 0px;
                        .bordaFoto2 {
                            display: block;
                            margin: auto;
                            overflow: hidden;
                            border-radius: 50%;
                            border: $verde 4px solid;
                            .foto {
                                display: block;
                                width: 250px;
                                height: 250px;
                                margin: auto;
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                    h3 {
                        font-size: 24px;
                        line-height: 32px;
                        color: $cinza;
                        text-align: center;
                        margin-bottom: 0px;
                    }
                    .cargo {
                        font-size: 24px;
                        line-height: 32px;
                        color: $cinza;
                        text-align: center;
                    }
                    .botoes {
                        margin-top: 10px;
                        a {
                            display: block;
                            background-color: $verde;
                            padding: 10px;
                            text-align: center;
                            color: #FFF;
                            font-size: 24px;
                            line-height: 32px;
                            margin-bottom: 5px;
                            &:hover {
                                background-color: $roxo;
                                text-decoration: none;
                            }
                            &:active {
                                background-color: $roxo;
                                text-decoration: none;
                            }
                        }
                    }
                    .perfil {
                        h3 {
                            text-align: left;
                            color: $roxo;
                        }
                        .cargo {
                            text-align: left;
                            margin-bottom: 50px;
                            color: $roxo;
                        }
                        .texto {
                            font-size: 20px;
                            line-height: 32px;
                            margin-bottom: 40px;
                        }
                        .link {
                            font-size: 20px;
                            line-height: 32px;
                            a {
                                color: $roxo;
                                &:hover {
                                    text-decoration: none;
                                    color: $verde;
                                }
                            }
                        }
                        &.right {
                            h3 {
                                text-align: right;
                            }
                            .cargo {
                                text-align: right;
                            }
                        }
                    }
                }
            }
            &.perfilPage {
                .psicologos {
                    .psicologo {
                        position: relative;
                        margin-bottom: 100px;
                        &:before {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 4px;
                            background-image: url($img+'quemSomos/separador.png');
                            background-repeat: no-repeat;
                            background-position: center center;
                            bottom: -50px;
                        }
                    }
                }
            }
        }
    }
    .contato {
        .poligono {
            background-image: url($img+"contato/poligono.png");
        }
        h1 {
            margin-top: 50px;
        }
    }
}
