/*
  SASS Variables for project
 */
$version : "v1.0.1";
$author : "Alexsander Moraes";

// COLORS DEFAULT
$azulForte: #007B99;
$azulFraco: #00B8E6;
$cinza: #4e4e4e;
$cinzaForte: #4A4A4A;

$roxo: #755385;
$verde: #8cbf43;
$marrom: #ad7241;


// DEFAULT FONT
$fontPadrao : 'Biko';

// PATHS
$fontsSRC : "assets/fonts/";
$img : "assets/imgs/";

$containerWidth: 1170px;

// BREAKPOINTS
$breakpoints : (max: (xs: 767px), min: (sm: 768px, md: 992px, lg: 1200px));