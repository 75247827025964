/*!
Theme Name: Conectare
Theme URI: https://www.agenciadominio.com.br/
Description: Site desenvolvido para uso exclusivo da Agencia closer;
Author: Agencia Dominio
Author URI: https://www.agenciadominio.com.br/
Version: 1.0
*/

// Imports
@import "imports";
@import "variaveis";
@import "mixins/mixins";
@import "fonts";
@import "padroes";
@import "header";
@import "paginas";
@import "templates/vitrine";
@import "templates/conectare";
@import "templates/clinica";
@import "templates/servicos";
@import "templates/contato";
@import "pages/clinica";
@import "pages/quemSomos";
@import "pages/cursos";
@import "pages/acontece";
@import "pages/publicacoes";
@import "pages/historia";
@import "pages/contato";
@import "pages/cadastro";
@import "footer";


@include breakpoint('768px', 'max') {
    .noBr {
        display: none;
    }
    .desktop {
        display: none !important;
    }
    .mobile {
        display: block !important;
    }
}