@mixin breakpoint($breakpoint, $break: null) {
  @if $break != null {
    $mediaVal : $breakpoint;

    @each $type, $value in $breakpoints {
      @if map-has-key($value, $breakpoint) {
        $mediaVal : map-get($value, $breakpoint);
      }
    }

    @media screen and (#{$break}-width: #{$mediaVal}) {
      @content;
    }
  } @else {
    @each $type, $value in $breakpoints {
      @if map-has-key($value, $breakpoint) {
        @media screen and (#{$type}-width: #{map-get($value, $breakpoint)}) {
          @content;
        }
      }
    }
  }
}

@function lineHeight($fontSize, $lineHeight) {
  @return #{$fontSize}/#{$lineHeight};
}