* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

body {
    font: lineHeight(16px, 160%) $fontPadrao;
    overflow-x: hidden !important;
    padding: 0;
    margin: 0;
    width: 100vw;
    background-color: #eeeeee;
    background-image: url($img + 'geral/background.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    max-width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

.mobile {
    display: none !important;
}

.container {
    width: 100%;
    max-width: $containerWidth;
    // @include breakpoint("1440px", "max") {
    //     max-width: 1170px;
    // }
}

.hiddenElement {
    visibility: hidden;
    @include breakpoint('1024px', 'max') {
        visibility: visible;
    }
}

.hiddenText {
    text-indent: 1000%;
    white-space: nowrap;
    overflow: hidden;
}

.mb7 {
    margin-bottom: 7px
}

.mb10 {
    margin-bottom: 10px
}

.mb15 {
    margin-bottom: 15px
}

.mb20 {
    margin-bottom: 20px
}

.mb30 {
    margin-bottom: 30px
}

.mb40 {
    margin-bottom: 40px
}

.mb50 {
    margin-bottom: 50px
}

.noShow {
    display: none;
}

.table {
    display: table;
    width: 100%;
    &.full {
        height: 100vh;
    }
    .tCell {
        width: 100%;
        vertical-align: middle;
        text-align: center;
        display: table-cell;
        &.top {
            vertical-align: top;
        }
        &.bottom {
            vertical-align: bottom;
        }
        .dib {
            display: inline-block;
        }
    }
}

.show {
    display: block !important;
}

.error {
    color: #BF1421;
    display: none;
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
    &.show {
        display: block;
    }
}

.errorField {
    border: 1px solid #BF1421 !important;
    background-color: #F9F1F1 !important;
}

select::-ms-expand {
    display: none;
}

.inline-block {
    display: inline-block !important;
}

.modal {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    background-color: rgba(238, 238, 238, 0.90);
    width: 100%;
    height: 100%;
    top: 0;
    display: none;
    .containerModal {
        width: 100%;
        max-width: 1170px;
        position: relative;
        margin: auto;
        .close {
            position: absolute;
            bottom: 70px;
            right: 0px;
            width: 30px;
            height: 30px;
            // background-image: url($img + 'gerais/close.png');
            // background-position: center center;
            // background-repeat: no-repeat;
            opacity: 1;
            &:hover {
                //background-image: url($closeMiniOn);
                cursor: pointer;
            }
        }
        .dadosConteudo {
            text-align: justify;
            padding-right: 100px;
            h1 {
                color: #fec593;
                margin: 0 0 40px 0;
                letter-spacing: 0;
                font-weight: 700;
                text-transform: none;
            }
            p {
                color: #505766;
                font-size: 18px;
                line-height: 25px;
                font-style: italic;
            }
            .msgCadastro {
                font-weight: 700;
                font-size: 18px;
                color: #505766;
                position: relative;
                width: 100%;
                padding: 40px 0;
            }
        }
    }
    @include breakpoint('768px', 'max') {
        .containerModal {
            height: 100vh;
            overflow-y: auto;
            width: 100vw;
            padding: 80px 30px 30px;
            display: block;
            .close {
                top: -60px;
            }
            .dadosConteudo {
                padding-right: 0;
                h1 {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

.removeSpacing {
    letter-spacing: -.31em;
    * {
        letter-spacing: normal;
    }
}

.align {
    float: none;
    &.middle {
        display: inline-block;
        vertical-align: middle;
    }
    &.top {
        display: inline-block;
        vertical-align: top;
    }
    &.bottom {
        display: inline-block;
        vertical-align: bottom;
    }
}


/*//////////////////////////////////////////////////////////////////////////////////// BOTOES PADROES ////*/

.btn-grey {
    color: $cinza;
    background-color: #eaebea;
    border: #fff 0px solid;
    padding: 8px 40px;
    border-radius: 0;
    font-size: 24px;
    font-family: $fontPadrao;
    font-weight: 400;
    font-style: italic;
    outline: none;
    &:hover {
        background-color: $roxo;
        text-decoration: none;
        color: #FFF;
    }
    &:focus {
        background-color: $roxo;
        outline: none;
        text-decoration: none;
        color: #FFF;
    }
}