.clinica {
    display: table;
    width: 100%;
    height: auto;
    margin-top: -160px;
    padding: 160px 0 100px;
    background-image: url($img+"clinica/background.jpg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    position: relative;
    z-index: 1;
    article {
        padding-top: 50px;
        h1 {
            color: $verde;
        }
        h2 {
            color: $roxo;
        }
        .texto {
            padding-right: 120px;
            @include breakpoint(768px, max) {
                padding-right: 0;
            }
        }
        .topicos {
            .topico {
                padding: 20px 18px;
                background-color: #fff;
                box-shadow: 0px 11px 21px 0px rgba(0, 0, 0, 0.38);
                height: 410px;
                margin: 10px;
                width: 46%;
                @include breakpoint(768px, max) {
                    width:auto;
                    height: auto;
                }
                h3 {
                    color: $roxo;
                    font-size: 24px;
                    line-height: 24px;
                    margin-bottom: 10px;
                }
                .destaque {
                    font-weight: bold;
                    margin-bottom: 10px;
                    text-align: center;
                }
                .texto {
                    font-size: 18px;
                    padding-right: 0;
                    height: auto;
                    text-align: center;
                    @include breakpoint(768px, max) {
                        height: auto;
                    }
                }
                .leiaMais {
                    text-align: right;
                    a {
                        display: inline-block;
                        height: auto;
                    }
                }
            }
        }
    }
}
