.historia {
    position: relative;
    z-index: 2;
    .poligono {
        position: relative;
        display: table;
        width: 100%;
        height: 160px;
        z-index: 2;
        background-image: url($img+"conectare/poligono.png");
        background-repeat: no-repeat;
        background-position: center top;
    }
    article {
        position: relative;
        &.chamada {
            background-color: $roxo;
            padding-top: 50px;
            z-index: 2;
            .foto {
                margin-bottom: 50px;
            }
            .texto {
                color: #ffffff;
                text-align: center;
            }
        }
        &.conteudo {
            background-color: rgba(#fff, 0.8);
            margin: -160px 0;
            padding: 200px 0;
            z-index: 1;
            h3 {
                color: $cinza;
                font-size: 35px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 50px;
            }
            .texto {
                font-size: 18px;
            }
        }
        .bordaTexto {
            border: $verde 3px solid;
            padding: 30px;
            font-size: 18px;
            margin-bottom: 40px;
        }
    }
    .contato {
        margin-top: -160px;
        .poligono {
            background-image: url($img+"contato/poligono.png");
        }
        h1 {
            margin-top: 50px;
        }
    }
}
