.cursos {
    position: relative;
    z-index: 2;
    .poligono {
        position: relative;
        display: table;
        width: 100%;
        height: 160px;
        z-index: 2;
        background-image: url($img+"cursos/poligono.png");
        background-repeat: no-repeat;
        background-position: center top;
    }
    article {
        position: relative;
        &.chamada {
            background-color: $verde;
            padding-top: 50px;
            z-index: 2;
            .foto {
                margin-bottom: 50px;
            }
            .texto {
                color: #ffffff;
                text-align: center;
            }
        }
        &.conteudo {
            background-color: rgba(#fff, 0.8);
            margin: -160px 0;
            padding: 200px 0;
            z-index: 1;
            .container {
                max-width: 950px;
                .cursos {
                    .curso {
                        position: relative;
                        margin-bottom: 50px;
                        figure {
                            position: relative;
                            overflow: hidden;
                            img {
                                position: relative;
                                z-index: 1;
                            }
                            .titulo {
                                display: flex;
                                align-items: center;
                                text-align: center;
                                position: absolute;
                                width: 100%;
                                z-index: 2;
                                top: 0px;
                                bottom: 0px;
                                font-size: 16px;
                                line-height: 24px;
                                color: #ffffff;
                                padding: 25px;
                                font-weight: bold;
                                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#755385+0,7db9e8+100&1+0,0+100 */
                                background: -moz-linear-gradient(
                                    left,
                                    rgba(117, 83, 133, 1) 0%,
                                    rgba(125, 185, 232, 0) 100%
                                ); /* FF3.6-15 */
                                background: -webkit-linear-gradient(
                                    left,
                                    rgba(117, 83, 133, 1) 0%,
                                    rgba(125, 185, 232, 0) 100%
                                ); /* Chrome10-25,Safari5.1-6 */
                                background: linear-gradient(
                                    to right,
                                    rgba(117, 83, 133, 1) 0%,
                                    rgba(125, 185, 232, 0) 100%
                                ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#755385', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
                                transition: all .4s ease-in-out;
                                @include breakpoint(768px, max) {
                                    font-size: 24px;
                                    line-height: 24px;
                                    padding: 15px;
                                }
                            }
                        }
                        h3 {
                            display: table;
                            width: 100%;
                            font-size: 62px;
                            color: $verde;
                            text-align: center;
                            line-height: 42px;
                            font-weight: bold;
                            text-decoration: none;
                            margin-top: 20px;
                            transition: all .4s ease-in-out;
                            @include breakpoint(768px, max) {
                                font-size: 38px;
                                line-height: 38px;
                            }
                        }
                        .linhaLinks {
                            margin-top: 5px;
                            .colLinkLeft {
                                padding-right: 3px;
                            }
                            .colLinkRight {
                                padding-left: 3px;
                            }
                            a {
                                display: block;
                                background-color: $roxo;
                                color: $verde;
                                text-align: center;
                                padding: 4px 8px;
                                font-size: 18px;
                                font-weight: bold;
                                &:hover {
                                    background-color: $verde;
                                    text-decoration: none;
                                    color: $roxo;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.single {
            .container {
                max-width: 950px;
                &.normal {
                    max-width: $containerWidth;
                }
                h1 {
                    font-size: 36px;
                    line-height: 36px;
                    color: $roxo;
                    text-align: center;
                }
                .separador {
                    display: table;
                    width: 100%;
                    height: 10px;
                    background-color: $verde;
                    margin: 40px 0 80px;
                }
                .conteudoTexto {
                    display: table;
                    margin: auto;
                    max-width: 950px;
                    font-size: 24px;
                    line-height: 32px;
                    color: $roxo;
                    .topico {
                        span {
                            font-weight: bold;
                            color: $verde;
                        }
                    }
                    .texto {
                        color: $roxo;
                        line-height: 40px;
                    }
                    .vagas {
                        font-size: 36px;
                        line-height: 40px;
                        font-weight: bold;
                        color: $roxo;
                    }
                    .lnkInscrevase {
                        display: table;
                        width: auto;
                        height: auto;
                        margin: 30px auto 0;
                        background-color: $verde;
                        color: $roxo;
                        font-size: 60px;
                        line-height: 60px;
                        padding: 20px 40px;
                        font-weight: bold;
                        &:hover {
                            text-decoration: none;
                            color: $verde;
                            background-color: $roxo;
                        }
                    }
                }
            }
        }
    }
    .contato {
        .poligono {
            background-image: url($img+"contato/poligono.png");
        }
        h1 {
            margin-top: 50px;
        }
    }
}
