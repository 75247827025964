.contato {
    .poligono {
        display: table;
        width: 100%;
        height: 160px;
        background-image: url($img+"contato/poligono.png");
        background-repeat: no-repeat;
        background-position: center top;
    }
    article {
        background-color: $marrom;
        .formulario {
            display: table;
            width: 100%;
            margin-bottom: 50px;
            padding-right: 40px;
            @include breakpoint("768px", "max") {
                padding-right: 0;
                display: block;
            }
            label {
                color: #4a4a4a;
                font-size: 15px;
                line-height: 21px;
            }
            .form-control {
                border: #fff 1px solid;
                box-shadow: none;
                border-radius: 0;
                height: auto;
                font-size: 24px;
                padding: 8px 15px;
                color: $cinza;
                font-style: italic;
                &#mensagem {
                    height: 150px;
                }
            }
            .botao {
                text-align: right;
                .btn {
                    display: inline-block;
                }
            }
        }
        .coluna {
            position: relative;
            padding-left: 40px;
            @include breakpoint(768px, max) {
                padding-left: 15px;
            }
            &:before {
                position: absolute;
                content: "";
                width: 1px;
                height: 220px;
                left: 10px;
                top: 55px;
                background-color: #fff;
                @include breakpoint(768px, max) {
                    display: none;
                }
            }
            .dados {
                margin-bottom: 40px;
                p {
                    color: #fff;
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            .mapa {
                @include breakpoint(768px, max) {
                    margin-bottom: 40px;
                }
            }
            .menu {
                ul {
                    display: table;
                    width: auto;
                    margin: auto;
                    @include breakpoint(768px, max) {
                        margin: 0 0 30px;
                    }
                    li {
                        list-style-type: none;
                        a {
                            color: #fff;
                            font-size: 18px;
                            line-height: 30px;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}
