header {
    padding: 70px 0 18px 0;
    @include breakpoint(768px, max) {
        padding: 18px 0;
    }
    .cabecalho {
        position: relative;
        z-index: 1;
        .redes {
            margin-top: 70px;
            @include breakpoint(768px, max) {
                margin-top: 20px;
            }
            ul {
                margin: 0;
                padding: 0;
                float: right;
                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-left: 15px;
                    @include breakpoint(768px, max) {
                        margin-left: 6px;
                    }
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .menu {
        padding: 30px 0;
        @include breakpoint('768px', 'max') {
            padding: 0;
        }
        .botaoResponsivo {
            position: relative;
            width: auto;
            min-width: 150px;
            height: 60px;
            right: 0px;
            display: none;
            margin-top: 0;
            @include breakpoint('768px', 'max') {
                display: block;
                min-width: initial;
                width: 47px;
                height: 36px;
                margin-top: 15px;
                position: absolute;
                right: 20px;
                top: 160px;
                z-index: 3;
            }
            @include breakpoint('480px', 'max') {
                top: 125px;
            }
            @include breakpoint('380px', 'max') {
                top: 110px;
            }
            @include breakpoint('320px', 'max') {
                top: 85px;
            }
            .linhasmr {
                float: right;
                margin-top: 8px;
                .b1 {
                    top: 0px;
                }
                .b2 {
                    top: 10px;
                }
                .b3 {
                    top: 20px;
                }
                > span {
                    background-color: $verde;
                    height: 5px;
                    right: 0;
                    position: absolute;
                    top: 0;
                    transition: all 0.3s ease 0s;
                    width: 40px;
                }
            }
            .ativo {
                .b1 {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    top: 21px;
                    right: 0;
                    @include breakpoint('768px', 'max') {
                        top: 14px;
                    }
                }
                .b2 {
                    width: 0px;
                }
                .b3 {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    top: 21px;
                    right: 0;
                    @include breakpoint('768px', 'max') {
                        top: 14px;
                    }
                }
            }
            &:hover {
                cursor: pointer;
                text-decoration: none;
            }
        }
        nav {
            transition: all .4s ease;
            @include breakpoint('768px', 'max') {
                display: none;
                overflow: auto;
            }
            ul {
                position: relative;
                margin: 0;
                li {
                    list-style-type: none;
                    display: inline-block;
                    vertical-align: middle;
                    @include breakpoint('768px', 'max') {
                        display: block;
                        padding: 0 30px;
                    }
                    a {
                        position: relative;
                        text-align: left;
                        font-size: 17px;
                        line-height: 24px;
                        padding: 10px 15px;
                        display: block;
                        color: $verde;
                        @include breakpoint('1024px', 'max') {
                            padding: 10px 15px;
                        }
                        @include breakpoint('768px', 'max') {
                            padding: 10px 0;
                            color: #fff;
                            border-bottom: #FFF 1px solid;
                        }
                        &:after {
                            position: absolute;
                            content: '';
                            width: 1px;
                            height: 24px;
                            background-color: $cinza;
                            right: 0;
                            @include breakpoint('768px', 'max') {
                                display: none;
                            }
                        }
                        &:hover {
                            color: $roxo;
                            text-decoration: none;
                        }
                        &.noMargin {
                            margin-right: 0;
                        }
                    }
                    &.ativo {
                        a {
                            color: $roxo;
                        }
                    }
                    &:first-child {
                        a {
                            padding-left: 0;
                        }
                    }
                    &:last-child {
                        a {
                            padding-right: 0;
                            border-bottom: 0px;
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
            &.ativo {
                display: block;
                position: absolute;
                width: 100vw;
                background-color: $roxo;
                left: 0;
                top: 168px;
                z-index: 4;
                padding: 20px 0;
                border-bottom: $verde 4px solid;
                @include breakpoint('768px', 'max') {
                    top: 225px;
                }
                @include breakpoint('480px', 'max') {
                    top: 185px;
                }
                @include breakpoint('380px', 'max') {
                    top: 168px;
                }
                @include breakpoint('320px', 'max') {
                    top: 145px;
                }
            }
        }
    }
}
