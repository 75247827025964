@font-face {
    font-family: "FontAwesome";
    src: url($fontsSRC+"fontawesome-webfont.eot?v=4.6.3");
    src: url($fontsSRC+"fontawesome-webfont.eot?#iefix&v=4.6.3")
            format("embedded-opentype"),
        url($fontsSRC+"fontawesome-webfont.woff2?v=4.6.3") format("woff2"),
        url($fontsSRC+"fontawesome-webfont.woff?v=4.6.3") format("woff"),
        url($fontsSRC+"fontawesome-webfont.ttf?v=4.6.3") format("truetype"),
        url($fontsSRC+"fontawesome-webfont.svg?v=4.6.3#fontawesomeregular")
            format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Biko";
    src: url($fontsSRC+"Biko.eot");
    src: url($fontsSRC+"Biko.woff2") format("woff2"), url($fontsSRC+"Biko.woff") format("woff"),
        url($fontsSRC+"Biko.ttf") format("truetype"), url($fontsSRC+"Biko.svg#Biko") format("svg"),
        url($fontsSRC+"Biko.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Biko";
    src: url($fontsSRC+"Biko-Bold.eot");
    src: url($fontsSRC+"Biko-Bold.woff2") format("woff2"),
        url($fontsSRC+"Biko-Bold.woff") format("woff"),
        url($fontsSRC+"Biko-Bold.ttf") format("truetype"),
        url($fontsSRC+"Biko-Bold.svg#Biko-Bold") format("svg"),
        url($fontsSRC+"Biko-Bold.eot?#iefix") format("embedded-opentype");
    font-weight: bold;
    font-style: normal;
}
