h1 {
    font-weight: bold;
    font-size: 35px;
    line-height: 35px;
    padding: 0;
    margin: 0 0 20px;
    color: #ffffff;
}
h2 {
    font-size: 24px;
    line-height: 24px;
    padding: 0;
    margin: 0 0 30px;
    color: $verde;
    font-style: italic;
}
.texto {
    color: $cinza;
    font-size: 24px;
    line-height: 24px;
    p {
        margin-bottom: 30px;
    }
}
.leiaMais {
    a {
        display: table;
        width: auto;
        position: relative;
        margin: 20px 0 30px;
        color: $verde;
        font-size: 18px;
        line-height: 24px;
        transition: all .3s ease-in-out;
        &:after {
            position: absolute;
            content: '';
            width: 60px;
            height: 3px;
            left: 0;
            bottom: -3px;
            background-color: $roxo;
            transition: all .2s ease-in-out;
        }
        &:hover {
            text-decoration: none;
            &:after {
                width: 100%;
                background-color: $verde;
            }
        }
    }
}