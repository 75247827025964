.cadastro {
    position: relative;
    z-index: 2;
    .poligono {
        position: relative;
        display: table;
        width: 100%;
        height: 160px;
        z-index: 2;
        background-image: url($img+"cursos/poligono.png");
        background-repeat: no-repeat;
        background-position: center top;
    }
    article {
        position: relative;
        &.chamada {
            background-color: $verde;
            padding-top: 50px;
            z-index: 2;
            .foto {
                margin-bottom: 50px;
            }
            .texto {
                color: #ffffff;
                text-align: center;
            }
        }
        &.single {
            .container {
                width: 100%;
                max-width: 600px;
                h1 {
                    font-size: 36px;
                    line-height: 36px;
                    color: $roxo;
                    text-align: center;
                }
                .separador {
                    display: table;
                    width: 100%;
                    height: 10px;
                    background-color: $verde;
                    margin: 40px 0 80px;
                }
                .conteudoTexto {
                    display: table;
                    margin: auto;
                    width: 100%;
                    max-width: 950px;
                    font-size: 24px;
                    line-height: 32px;
                    color: $roxo;
                    .formulario {
                        display: table;
                        width: 100%;
                        button {
                            background-color: $verde;
                        }
                    }
                }
            }
        }
    }
    .contato {
        .poligono {
            background-image: url($img+"contato/poligono.png");
        }
        h1 {
            margin-top: 50px;
        }
    }
}
