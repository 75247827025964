.vitrine {
    display: block;
    width: 100%;
    max-width: 1070px;
    margin: auto auto 140px;
    @include breakpoint('768px', 'max') {
        margin-bottom: 40px;
    }
    .owl-carousel {
        height : 600px;
        @include breakpoint('768px', 'max') {
            height: 200px;
        }
        .item {
            height: 600px;
            background-repeat : no-repeat;
            background-position: center center;
            background-size: cover;
            @include breakpoint('768px', 'max') {
                height: 200px;
            }
        }
        .owl-nav {
            width: 100%;
            div {
                position: absolute;
                display: block;
                width: 20px;
                height: 34px;
                z-index: 9999999;
                top: 50%;
                margin-top: -14px;
                text-indent: 1000%;
                white-space: nowrap;
                overflow: hidden;
            }
            .owl-prev {
                left: -30px;
                background: transparent url($img + 'vitrine/seta-left.png') no-repeat 0 0;
                @include breakpoint(768px, max) {
                    left: 30px;
                }
            }
            .owl-next {
                right: -30px;
                background: transparent url($img + 'vitrine/seta-right.png') no-repeat 0 0;
                @include breakpoint(768px, max) {
                    right: 30px;
                }
            }
        }
    }
    // @include breakpoint('768px', 'max'){
    //     .owl-carousel {
    //         .item {
    //             min-height: auto;
    //             height: calc(75vh - 50px);
    //             padding: 0 20px;
    //         }
    //     }
    // }
}