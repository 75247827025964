.selectric-wrapper {
    position: relative;
    cursor: pointer;
}

.selectric-responsive {
    width: 100%;
}

.selectric {
    border: 1px solid #000000;
    background: #ffffff;
    position: relative;
    border-radius: 0px;
    .label {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 48px 0 10px;
        font-size: 12px;
        line-height: 48px;
        color: #000000;
        height: 48px;
    }
    .button {
        background: transparent;
        border-radius: 0px;
        display: block;
        position: absolute;
        right: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 34px;
        height: 34px;
        color: #000000;
        text-align: center;
        font: 0/0 a;
        *font: 34px/34px Lucida Sans Unicode, Arial Unicode MS, Arial;
        &:after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: #000000;
            border-bottom: none;
        }
    }
}

.selectric-focus .selectric {
    border-color: #aaa;
}

.selectric-hover .selectric {
    border-color: #000000;
    .button {
        color: #000000;
        &:after {
            border-top-color: #000000;
        }
    }
}

.selectric-open {
    z-index: 9999;
    .selectric {
        border-color: #000000;
        background: #e6e6e6;
    }
    .selectric-items {
        display: block;
    }
}

.selectric-disabled {
    filter: alpha(opacity = 50);
    opacity: 0.5;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectric-hide-select {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;
    select {
        position: absolute;
        left: -100%;
    }
    &.selectric-is-native {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        select {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border: none;
            z-index: 1;
            box-sizing: border-box;
            opacity: 0;
        }
    }
}

.selectric-input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    outline: none !important;
    border: none !important;
    *font: 0/0 a !important;
    background: none !important;
}

.selectric-temp-show {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important;
}

/* Items box */

.selectric-items {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #f8f8f8;
    border: 1px solid #c4c4c4;
    z-index: -1;
    box-shadow: 0 0 10px -6px;
    .selectric-scroll {
        height: 100%;
        overflow: auto;
    }
}

.selectric-above .selectric-items {
    top: auto;
    bottom: 100%;
}

.selectric-items {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
        line-height: 20px;
        min-height: 20px;
    }
    li {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
        line-height: 20px;
        min-height: 20px;
        display: block;
        padding: 8px;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #eee;
        color: #666;
        cursor: pointer;
        &.selected {
            background: #efefef;
            color: #444;
        }
        &.highlighted {
            background: #d0d0d0;
            color: #444;
        }
        &:hover {
            background: #f0f0f0;
            color: #444;
        }
    }
    .disabled {
        filter: alpha(opacity = 50);
        opacity: 0.5;
        cursor: default !important;
        background: none !important;
        color: #666 !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .selectric-group {
        .selectric-group-label {
            font-weight: bold;
            padding-left: 10px;
            cursor: default;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background: none;
            color: #444;
        }
        &.disabled li {
            filter: alpha(opacity = 100);
            opacity: 1;
        }
        li {
            padding-left: 25px;
        }
    }
}
