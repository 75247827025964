.clinicaPage {
    position: relative;
    z-index: 2;
    .poligono {
        position: relative;
        display: table;
        width: 100%;
        height: 160px;
        z-index: 2;
        background-image: url($img+"clinica/poligono.png");
        background-repeat: no-repeat;
        background-position: center top;
    }
    article {
        position: relative;
        &.chamada {
            background-color: $marrom;
            padding-top: 50px;
            z-index: 2;
            .foto {
                margin-bottom: 50px;
            }
            .texto {
                color: #ffffff;
                text-align: center;
            }
        }
        &.conteudo {
            background-color: rgba(#FFF, 0.8);
            margin: -160px 0;
            padding: 200px 0;
            z-index: 1;
            h1 {
                color: $verde;
            }
            h2 {
                color: $roxo;
            }
            .topicos {
                .topico {
                    padding: 40px;
                    margin-bottom: 40px;
                    &.rosa {
                        background-color: rgba($roxo, 0.1);
                    }
                    &.verde {
                        background-color: rgba($verde, 0.1);
                    }
                    h3 {
                        color: $cinza;
                        margin-top: 0;
                    }
                    .texto {
                        font-size: 18px;
                    }
                }
            }
            .conteudoServico {
                background-color: rgba($roxo, 0.1);
                padding: 40px 20px 20px;
                h1 {
                    font-size: 40px;
                    color: #4e4e4e;
                }
                .texto {
                    color: #4e4e4e;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }
    .contato {
        .poligono {
            background-image: url($img+'clinica/poligono-contato.png');
        }
        h1 {
            margin-top: 50px;
        }
    }
}