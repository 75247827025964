.servicos {
    position: relative;
    z-index: 1;
    article {
        padding: 85px 0 50px;
        h1 {
            color: $verde;
            text-align: right;
        }
        h2 {
            color: $roxo;
            text-align: right;
        }
        ul {
            margin-top: 80px;
            li {
                list-style-type: none;
                display: table;
                width: 100%;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 30px;
                a {
                    @include breakpoint(768px, max) {
                        width: 100%;
                    }
                    .icone {
                        float: left;
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                    .text {
                        float: left;
                        width: 90%;
                        color: #4e4e4e;
                    }
                    &:hover {
                        color: $roxo;
                        text-decoration: none;
                    }
                }
            }
        }
        .roxo {
            ul {
                li {
                    .icone {
                        background-color: $roxo;
                    }
                }
            }
        }
        .verde {
            ul {
                li {
                    .icone {
                        background-color: $verde;
                    }
                }
            }
        }
    }
}
